export const createDate = (year: number, month: number, day: number) => {
  const date = new Date();
  
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  date.setFullYear(year);
  date.setMonth(month);
  date.setDate(day);

  return date;
}

export const isDateInRange = (min: Date, max:Date, target: Date) => {
  return min <= target && target <= max;
/* 
  if(!(min.getFullYear() <= target?.getFullYear() && target?.getFullYear() <= max?.getFullYear())) return false;
  if(!(min.getMonth() <= target?.getMonth() && target?.getMonth() <= max?.getMonth())) return false;
  
  return (min.getDate() <= target?.getDate() && target?.getDate() <= max?.getDate()); */
}
