import { RequestHeader, RequestQueryParamInterface } from "../types";
import { httpBasic } from "./httpBasic";

export const httpPost = <ResponseType>(
  url: string,
  data?: any,
  queryParams?: RequestQueryParamInterface,
  headers?: RequestHeader
) => {
  return httpBasic<ResponseType>("POST", url, headers, queryParams, data);
};