export const toReadableDateOnly = (datetime: string | Date) => {
  const baseDate = typeof datetime === "string" ? new Date(datetime) : datetime;

  const monthString = baseDate
    .toLocaleString("default", { month: "long" })
    .slice(0, 3);
  const yearString = baseDate.getFullYear();
  const dayString = baseDate.getDate();

  return `${monthString} ${dayString} ${yearString}`;
};
