import { styled } from "styled-components";

const ValueStyle = styled.span`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
`

interface ValueInterface {
 children?: string | number;
}

export const Value = ({ children = "NONE" }: ValueInterface) => {
 return <ValueStyle>{children}</ValueStyle>
};
