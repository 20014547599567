import { randomPick } from "./randomPick";

export const CHARSET_NUMBERS  = '1234567890';
export const CHARSET_LLETTERS = 'qwertyuiopasdfghjklzxcvbnm';
export const CHARSET_CLETTERS = 'QWERTYUIOPLKJHGFDSAZXCVBNM';
export const CHARSET_SYMBOLS  = '!@#$%^&*()_+=-/][\'":{}|><.,`~';

export const randomString = (charset: string|string[], length: number):string => {
    if(Array.isArray(charset)) charset = charset.join('');

    let result = '';
    for(let i=0;i<length;i++)
        result += randomPick(charset);

    return result;
}
