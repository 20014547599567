export const JANUARY_INDEX = 0;
export const FEBRUARY_INDEX = 1;
export const MARCH_INDEX = 2;
export const APRIL_INDEX = 3;
export const MAY_INDEX = 4;
export const JUNE_INDEX = 5;
export const JULY_INDEX = 6;
export const AUGUST_INDEX = 7;
export const SEPTEMBER_INDEX = 8;
export const OCTOBER_INDEX = 9;
export const NOVEMBER_INDEX = 10;
export const DECEMBER_INDEX = 11;

export const MONTH_NAMES = {
  [JANUARY_INDEX]: "January",
  [FEBRUARY_INDEX]: "February",
  [MARCH_INDEX]: "March",
  [APRIL_INDEX]: "April",
  [MAY_INDEX]: "May",
  [JUNE_INDEX]: "June",
  [JULY_INDEX]: "July",
  [AUGUST_INDEX]: "August",
  [SEPTEMBER_INDEX]: "September",
  [OCTOBER_INDEX]: "October",
  [NOVEMBER_INDEX]: "November",
  [DECEMBER_INDEX]: "December"
};

export const MONTH_NUMBER_OF_DAYS = {
  [JANUARY_INDEX]: 31,
  [FEBRUARY_INDEX]: 28,
  [MARCH_INDEX]: 31,
  [APRIL_INDEX]: 30,
  [MAY_INDEX]: 31,
  [JUNE_INDEX]: 30,
  [JULY_INDEX]: 31,
  [AUGUST_INDEX]: 31,
  [SEPTEMBER_INDEX]: 30,
  [OCTOBER_INDEX]: 31,
  [NOVEMBER_INDEX]: 30,
  [DECEMBER_INDEX]: 31
};

export type MonthIndex = keyof typeof MONTH_NAMES;