import { RequestHeader, RequestQueryParamInterface } from "../types";
import { httpBasic } from "./httpBasic";

export const httpPut = <ResponseType>(
  url: string,
  data: any,
  queryParams: RequestQueryParamInterface = {},
  headers: RequestHeader = {}
) => {
  return httpBasic<ResponseType>("PUT", url, headers, queryParams, data);
};